<script>
//import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {getAccountingGLApi} from '@/api/accounting/query'


/**
 * User list component
 */
export default {
  page: {
    title: "Financial Statement List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "Financial Statement List",
      items: [
        {
          text: "Setting",
          href: "/",
        },
        {
          text: "Financial Statement",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "ID",
          sortable: true,
        },
        {
          key: "LineType",
          sortable: true,
        },
        {
          key: "Code",
          sortable: true,
        },
        {
          key: "Sign",
          sortable: true,
        },
        {
          key: "Description",
          sortable: true,
        },
        
        {
          key: "Action",
          sortable: true,
        },
      ],
      submitted: false,
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList(this.currentPage)
  },
  mounted() {
  
  },
  methods: {

    queryList(p) {
      getAccountingGLApi().fs_line_list({page : {page : p, page_size: this.perPage}}).then((res)=>{
        this.listingData = []
        res.data.map((o) =>{
        
          this.listingData.push({
            id: o.id,
            code :o.code,
            sign : o.sign,
            line_type : o.line_type,
            description : o.description,
          })
          this.totalRows = res.page.total;
        })  

      })
    },

    pageChange(p) {
      this.queryList(p)
    },
   

    handleSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      this.submitted = false;
    },

  },
  validations: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Financial Statement
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                 
                  <!-- end dropdown -->
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="listingData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  class="table-check"
                >

                  <template  #cell(ID)="data">
                    {{ data.item.id}}
                  </template>
                  <template #cell(LineType)="data"> 
                    {{ data.item.line_type}}
                  </template>
                  <template #cell(Code)="data">
                    {{ data.item.code}}
                  </template>
                  <template #cell(Sing)="data">
                    {{ data.item.sign}}
                  </template>
                  <template #cell(Description)="data">
                    {{ data.item.description }}
                  </template>
                
                  <template #cell(action)>
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item>Edit</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
